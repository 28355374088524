import * as React from 'react';
import PaneView from './PaneView';
import RejectContextMenu from './RejectContextMenu';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { getStory } from '../redux/actions/active-story';
import { getCachedPlaybackSettings } from '../redux/actions/playback-settings';
import { getCachedPanesState } from '../redux/actions/panes';
import { api, initApi, SERVICE_ID } from '../constants/app';

import {
    AppWrapper,
    ImposiumHeader,
    Spinner,
    ToastService,
    AuthService,
    SessionService,
    scrapeEmail,
    validateAccessLevel,
    getFirstStoryInOrg,
    Modal,
    getStoryPublishStatus,
    clearStoryPublishStatus,
    HEADER_HEIGHT,
    resetStoryFilter
} from '@imposium-hub/components';
import PublishWizard from '@imposium-hub/components/dist/esm/components/publish-wizard/PublishWizard';
import {
    setPublishWizard,
    selectExperience,
    clearExperiencesFilters
} from '../redux/actions/experiences';
import { createFreshBatch, deleteBatch, getBatches, setErrorModal } from '../redux/actions/batches';
import { getBatchExport, importBatchFromCsv, renderBatch } from '../redux/actions/batch-jobs';
import { initPendo } from '../util/pendo';
import { addTag, selectTag } from '../redux/actions/tags';
import { RowErrorModal } from './previewer/RowErrorModal';

interface IAppProps {
    access: any;
    auth: any;
    location: any;
    params: any;
    story: any;
    experiencesList: any;
    batchesList: any;
    getStory(id: string): any;
    getCachedPlaybackSettings: () => any;
    getCachedPanesState: () => any;
    clearStoryPublishStatus(apiInstance: any): void;
    getStoryPublishStatus(apiInstance: any, sId: string): any;
    setPublishWizard: (e: boolean) => any;
    setErrorModal: (e: boolean, i: any) => any;
    createFreshBatch: (e: string) => any;
    getBatches: () => any;
    getBatchExport: (batchId: string) => any;
    importBatchFromCsv: (
        storyId: string,
        batchId: string,
        batchName: string,
        csvFile: File,
        compId: string,
        embed: boolean,
        addMedia?: boolean
    ) => any;
    renderBatch: (batchId: string, postRenderActions?: any) => any;
    batchJobs: any;
    deleteBatch: (batchId: string) => any;
    selectExperience: (exp: any) => any;
    clearExperiencesFilters(): void;
    selectTag: (tag: any) => any;
    addTag: (tag: any) => any;
    resetStoryFilter(): any;
    storyFilter: any;
}

interface IAppState {
    isPublish: boolean;
}

class App extends React.PureComponent<IAppProps, IAppState> {
    constructor(props) {
        super(props);

        this.props.getCachedPanesState();
        this.props.getCachedPlaybackSettings();
    }

    /*
        Prep app once user is logged in successfully
     */
    public onAuthenticated = (activeOrgId: string, activeStoryId: string): void => {
        const {
            location: {
                query: { organization_id: orgIdParam }
            },
            params: { story_id: storyIdParam },
            auth: { idTokenPayload }
        } = this.props;

        initPendo(idTokenPayload);

        let urlParams: URLSearchParams;

        if (
            !orgIdParam ||
            !storyIdParam ||
            activeOrgId !== orgIdParam ||
            activeStoryId !== storyIdParam
        ) {
            urlParams = new URLSearchParams(window.location.search);
            urlParams.delete('organization_id');
            browserHistory.replace(
                `/${activeStoryId}?organization_id=${activeOrgId}&${urlParams.toString()}`
            );
        }

        if (activeOrgId) {
            initApi(activeOrgId, this.props.auth.accessToken);
            this.props.getStory(activeStoryId);
        }

        if (activeStoryId) {
            this.props.getStory(activeStoryId).then(() => {
                this.props
                    .getStoryPublishStatus(api, activeStoryId)
                    .catch((e) => ToastService.emit('error', e));
            });
        }
    };

    private onAuthenticationFailure = (): void => {
        this.doLogout(false);
    };

    private doLogout = (doFederatedLogout: boolean): void => {
        if (doFederatedLogout && SessionService.getSession()) {
            SessionService.removeSession();
            AuthService.logout();
        } else {
            browserHistory.push('/auth');
        }
    };

    private changeStory = (s: any): void => {
        const {
            auth: {
                idTokenPayload: { exp }
            },
            location: {
                query: { organization_id }
            },
            storyFilter
        } = this.props;
        if (storyFilter) {
            this.props.resetStoryFilter();
        }
        this.props.clearStoryPublishStatus(api);
        this.props.getStory(s.id);
        SessionService.updateSession({ story_id: s.id }, exp);
        browserHistory.replace(`/${s.id}?organization_id=${organization_id}`);
        this.props.getStoryPublishStatus(api, s.id).catch((e) => ToastService.emit('error', e));
        this.props.selectExperience({});
        this.props.clearExperiencesFilters();
    };

    private changeOrg = (newOrgId: string): void => {
        const {
            auth: {
                idTokenPayload: { exp }
            },
            access
        } = this.props;
        const activeStoryId: string = getFirstStoryInOrg(newOrgId, access);
        this.props.clearStoryPublishStatus(api);
        if (validateAccessLevel(newOrgId, SERVICE_ID, access)) {
            initApi(newOrgId, this.props.auth.accessToken);
            this.props.getStory(activeStoryId);
            this.props
                .getStoryPublishStatus(api, activeStoryId)
                .catch((e) => ToastService.emit('error', e));
        }
        SessionService.updateSession({ organization_id: newOrgId, story_id: activeStoryId }, exp);
        browserHistory.replace(`/${activeStoryId}?organization_id=${newOrgId}`);
        this.props.selectExperience({});
        this.props.clearExperiencesFilters();
    };

    private getProjectId = (data: any) => {
        for (const key in data.acts) {
            if (data.acts.hasOwnProperty(key)) {
                return data.acts[key].id;
            }
        }
    };

    public render() {
        const {
            auth,
            location: {
                query: { organization_id: orgIdParam, batch_id: batchIdParam }
            },
            params: { story_id: storyIdParam },
            story: { data },
            experiencesList: { publishWizardOpen },
            batchesList: {
                errorModal: { isOpen }
            },
            batchJobs
        } = this.props;

        const project = { actId: this.getProjectId(data) };

        const activeEmail: string = scrapeEmail(auth);

        const fromCrM = data && data.creativeId && data.creativeLibraryId;
        const CrMLink =
            fromCrM && data
                ? `${import.meta.env.VITE_CRM_BASE}/library/${data.creativeLibraryId}/creative/${
                      data.creativeId
                  }/versions/`
                : null;

        return (
            <AppWrapper
                baseUrl={import.meta.env.VITE_IMPOSIUM_BASE}
                serviceId={SERVICE_ID}
                auth0ClientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
                auth0Domain={import.meta.env.VITE_AUTH0_DOMAIN}
                organizationId={orgIdParam}
                storyId={storyIdParam}
                onAuthenticated={this.onAuthenticated}
                onAuthenticationFailure={this.onAuthenticationFailure}>
                <ImposiumHeader
                    email={activeEmail}
                    hideStoryPicker={fromCrM}
                    hideDocs={fromCrM}
                    hideOrgPicker={fromCrM}
                    showFTLogo={false}
                    CrMLink={CrMLink}
                    baseUrl={import.meta.env.VITE_IMPOSIUM_BASE}
                    activeOrganization={orgIdParam}
                    activeStory={storyIdParam}
                    onStoryChange={(s: any) => this.changeStory(s)}
                    onOrganizationChange={this.changeOrg}
                    logout={() => this.doLogout(true)}
                />

                <PaneView
                    organizationId={orgIdParam}
                    batchId={batchIdParam}
                />
                {this.props.story.loading && (
                    <div className='story-loader'>
                        <Spinner />
                    </div>
                )}
                <Modal
                    onRequestClose={() => this.props.setPublishWizard(false)}
                    wrapperStyle={{
                        top: HEADER_HEIGHT,
                        left: '0px',
                        width: '100%',
                        height: `calc(100% - ${HEADER_HEIGHT}px)`
                    }}
                    style={{
                        width: '600px',
                        height: '70%',
                        top: '10%',
                        left: 'calc((100% - 600px) / 2)'
                    }}
                    isOpen={publishWizardOpen}>
                    {data && (
                        <PublishWizard
                            fromCrM={fromCrM}
                            creativeManagerBaseUrl={import.meta.env.VITE_CRM_BASE}
                            api={api}
                            story={data}
                            project={project}
                            onClose={() => this.props.setPublishWizard(false)}
                            handleError={(e) => ToastService.emit('error', e)}
                            handleNotification={(i) => ToastService.emit('info', i)}
                            createFreshBatch={this.props.createFreshBatch}
                            getBatches={this.props.getBatches}
                            getBatchExport={this.props.getBatchExport}
                            importBatchFromCsv={this.props.importBatchFromCsv}
                            renderBatch={this.props.renderBatch}
                            batchJobs={batchJobs}
                            onDeleteBatch={this.props.deleteBatch}
                        />
                    )}
                </Modal>
                {isOpen && (
                    <RowErrorModal
                        setErrorModal={(a, i) => this.props.setErrorModal(a, i)}
                        selectTag={(t) => this.props.selectTag(t)}
                        addTag={(t) => this.props.addTag(t)}
                        batchesList={this.props.batchesList}
                    />
                )}
                <RejectContextMenu />
            </AppWrapper>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getStory,
            getCachedPlaybackSettings,
            getCachedPanesState,
            setPublishWizard,
            createFreshBatch,
            getBatches,
            getBatchExport,
            importBatchFromCsv,
            getStoryPublishStatus,
            clearStoryPublishStatus,
            renderBatch,
            deleteBatch,
            selectExperience,
            clearExperiencesFilters,
            setErrorModal,
            selectTag,
            addTag,
            resetStoryFilter
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        auth: state.auth,
        access: state.access,
        story: state.story,
        experiencesList: state.experiencesList,
        batchJobs: state.batchJobs,
        batchesList: state.batchesList,
        storyFilter: state.storyFilter.name
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
