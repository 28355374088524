import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { CRM_IMPORT_STATUS_COLORS } from '../../constants/misc';
import { BATCHES_COPY as copy } from '../../constants/copy';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { Button, Modal } from '@imposium-hub/components';
import { useState } from 'react';

interface IBatchesTableCRMImportsCellProps {
    cell: any; // React table 'cell' wrapper
}

const BatchesTableCRMImportsCell: React.FC<IBatchesTableCRMImportsCellProps> = (
    p: IBatchesTableCRMImportsCellProps
) => {
    const {
        original: { id, total_imported_to_crm, total_rows, crm_import_error }
    } = p.cell.row;
    const [showErrorModal, setShowErrorModal] = useState(false);
    const ICON_INFO_CIRCLE = <FontAwesomeIcon icon={faInfoCircle} />;
    let imported = 0;
    let unimported = total_rows;
    let error;
    const hasCRMImport = total_imported_to_crm !== null && total_imported_to_crm !== undefined;

    if (crm_import_error) {
        const splitResponse = crm_import_error.split(', response: ')[1];
        if (splitResponse) {
            const jsonResponse = JSON.parse(splitResponse);
            const { name, description, code } = jsonResponse;
            error = `Error Code: ${code} ${name} - ${description}`;
        } else {
            error = `Error: ${crm_import_error}`;
        }
    }

    if (hasCRMImport) {
        imported = total_imported_to_crm;
        unimported = total_rows - total_imported_to_crm;
    }

    const importedPerc = imported
        ? Math.floor(parseFloat((imported / total_rows).toFixed(2)) * 100)
        : 0;

    const unimportedPerc = unimported
        ? Math.floor(parseFloat((unimported / total_rows).toFixed(2)) * 100)
        : 0;

    const openErrorModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setShowErrorModal(true);
    };

    const checkError = () => {
        if (error) {
            return (
                <Button
                    customClass='importError'
                    key={id}
                    style='subtle'
                    size='small'
                    onClick={openErrorModal}>
                    {ICON_INFO_CIRCLE}
                </Button>
            );
        }
    };

    const modStatusBar = hasCRMImport && (
        <div className='status-bar'>
            {checkError()}
            <div className='status-bg'>
                {importedPerc ? (
                    <div
                        className='status-sub-bg imported'
                        style={{ width: `${importedPerc}%` }}
                    />
                ) : null}
                {unimportedPerc ? (
                    <div
                        className='status-sub-bg unimported'
                        style={{ width: `${unimportedPerc}%` }}
                    />
                ) : null}
            </div>
        </div>
    );

    const drillDown = hasCRMImport && (
        <span className='drill-down'>
            {imported ? (
                <p>
                    <FontAwesomeIcon
                        color={CRM_IMPORT_STATUS_COLORS.IMPORTED}
                        icon={faCircle}
                    />
                    &nbsp;{copy.crmImportStatus.imported} {imported}
                </p>
            ) : null}
            {unimported ? (
                <p>
                    <FontAwesomeIcon
                        color={CRM_IMPORT_STATUS_COLORS.UNIMPORTED}
                        icon={faCircle}
                    />
                    &nbsp;{copy.crmImportStatus.unimported} {unimported}
                </p>
            ) : null}
        </span>
    );

    return (
        <>
            <div className='mod-status-bar'>
                {modStatusBar}
                {drillDown}
            </div>
            <Modal
                onRequestClose={() => setShowErrorModal(false)}
                wrapperStyle={{
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: `100%`
                }}
                style={{
                    width: '400px',
                    height: '100px',
                    top: 'calc((100% - 100px) / 2)',
                    left: 'calc((100% - 400px) / 2)'
                }}
                isOpen={showErrorModal}>
                <div className='error-modal-wrapper'>{error}</div>
            </Modal>
        </>
    );
};

export default BatchesTableCRMImportsCell;
